import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-edit"
export default class extends Controller {
  bulkSelectTargets: HTMLSelectElement[]
  selectTargets: HTMLSelectElement[]

  static targets = ["bulkSelect", "select"]

  // Semaphore to prevent infinite loop due to tomselect triggering change event on setValue
  propagateEvents = true

  onInitializeFunction: (event) => void

  connect(): void {
    this.onInitializeFunction = this.onInitialize.bind(this)
    window.addEventListener("StyledSelect:initialize", this.onInitializeFunction)
  }

  disconnect(): void {
    window.removeEventListener("StyledSelect:initialize", this.onInitializeFunction)
  }

  private onInitialize(event): void {
    const select = event.detail.origin

    if (this.bulkSelectTargets.includes(select)) {
      this.refreshBulkSelect(select)
    }
  }

  handleBulkSelectUpdate(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const select = event.target
      const value = this.getValue(select)
      const columnName = this.getColumnName(select)

      this.getSelects(columnName).forEach((select) => this.setValue(select, value))
      this.resetPlaceholder(select)
      this.propagateEvents = true
    }
  }

  handleSelectUpdate(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const columnName = this.getColumnName(event.target)
      const bulkSelect = this.getBulkSelect(columnName)

      this.refreshBulkSelect(bulkSelect)

      if (this.isMultiSelect(bulkSelect)) {
        this.toggleCompactView(bulkSelect)
      }

      this.propagateEvents = true
    }
  }

  private refreshBulkSelect(select): void {
    const key = this.getColumnName(select)
    const values = this.getSelects(key).map((select) => this.getValue(select))
    const allEqual = values.length > 0 && values.every((value) => value === values[0])

    if (allEqual) {
      this.setValue(select, values[0])
      this.resetPlaceholder(select)
    } else {
      this.setValue(select, "")
      this.setMultipleValuesPlaceholder(select)
    }
  }

  private getValue(select): any {
    if (this.isMultiSelect(select)) {
      return Array.from(select.selectedOptions)
        .map((option) => option.value)
        .sort()
        .join(",")
    } else {
      return select.value
    }
  }

  private setValue(select, value): void {
    if (this.isMultiSelect(select)) {
      select.tomselect?.setValue(value?.split(","))
      this.toggleCompactView(select)
    } else {
      select.tomselect?.setValue(value)
    }
  }

  private isMultiSelect(select): boolean {
    return select.multiple
  }

  private getBulkSelect(columnName): any {
    return this.bulkSelectTargets.filter((select) => this.getColumnName(select) === columnName)[0]
  }

  private getSelects(columnName): any[] {
    return this.selectTargets.filter((select) => this.getColumnName(select) === columnName)
  }

  private getColumnName(element): string {
    return this.getColumn(element)?.dataset?.columnName
  }

  private getColumn(element): any {
    return element.closest(".table-cell")
  }

  private setMultipleValuesPlaceholder(select): void {
    const tomselect = select.tomselect

    if (tomselect) {
      tomselect.settings.placeholder = "Multiple Values"
      tomselect.wrapper.classList.add("darker-placeholder")
      tomselect.inputState()
    }
  }

  private resetPlaceholder(select): void {
    const tomselect = select.tomselect

    if (tomselect) {
      tomselect.settings.placeholder = "Select"
      tomselect.wrapper.classList.remove("darker-placeholder")
      tomselect.inputState()
    }
  }

  private toggleCompactView(select) {
    const tsControl = select.closest(".table-cell").getElementsByClassName("ts-control")[0]
    const selectedItems = tsControl.querySelectorAll('[id^="selected-item_"]')
    const elementsExceptFirst = Array.from(selectedItems).slice(1)

    elementsExceptFirst.forEach((element) => {
      if (element.classList.contains("!hidden")) return
      element.classList.add("!hidden")
    })

    if (elementsExceptFirst.length > 0) {
      select.tomselect.settings.placeholder = `+${elementsExceptFirst.length}`
    }
  }
}
