import React, { useContext } from "react"
import { useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"

interface ClickToEditTaskButtonProps {
  taskId: string
  isEditing: boolean
}

export const ClickToEditTaskButton = ({ taskId, isEditing }: ClickToEditTaskButtonProps) => {
  const { cardDimensions, editable } = useWorkflow()
  const buttonWidth = isEditing ? 100 : 160
  const clickToEditTaskButtonStyles = {
    position: "absolute",
    top: -40,
    width: `${buttonWidth}px`,
    left: `${cardDimensions.width / 2 - buttonWidth / 2}px`,
    pointerEvents: "all",
  }

  if (!editable) {
    return null
  }

  return (
    <div
      style={clickToEditTaskButtonStyles}
      id={`click-to-edit-task-${taskId}`}
      className={
        "cursor-pointer rounded-md flex flex-row space-x-2 justify-center text-white text-sm py-1.5 px-3 items-center font-medium bg-gray-800"
      }
    >
      <div className="flex flex-row space-x-2">
        <div data-testid="edit-task">{isEditing ? "Editing Task" : "Click card to edit task"}</div>
      </div>
    </div>
  )
}
