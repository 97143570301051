import React, { useEffect, useState } from "react"
import { Handle, Position, useReactFlow } from "reactflow"
import { AddTaskButton } from "./addTaskButton"
import { EditTaskButton } from "./editTaskButton"
import { getVisualBuilderHandleStyles, show, taskTriggersText } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { Trigger } from "../../../../utils/types"
import { taskState } from "./cardSelectedService"
import { SubworkflowTaskCardNode } from "./subworkflowTaskCardNode"
import clsx from "clsx"
import { ClickToEditTaskButton } from "./clickToEditTaskButton"
import { RequestStageIcon } from "./requestStageIcon"
import { debounce } from "lodash"

interface WorkflowTaskCardNodeProps {
  data: {
    taskId: string
    editPath: string
    taskNameAndTooltip: string | null
    badgeWithTooltip: string | null
    assigneeName: string | null
    assignmentType: string
    triggerCondition: string
    triggers: Trigger[]
    targets: string[]
    ruleBadge: string
    addTaskPathLeft?: string
    addTaskPathBottom?: string
    addTaskPathRight?: string
    cardHeader?: string
    cardBody?: string
    workflowGuideText?: string
    workflowGuideUrl?: string
    groupLabelName: string | null
    requestStage: string
    subworkflowPlaceholder: boolean | null
    subworkflowToApply: string | null
    subworkflowVisualizationUrl: string | null
    creditType: string | null
    integrationLogo: string | null
  }
}

export const WorkflowTaskCardNode = ({ data }: WorkflowTaskCardNodeProps) => {
  const {
    cardDimensions,
    editable,
    selectedTaskId,
    drawerOpened,
    isSubworkflow,
    hoveredTaskId,
    setHoveredTaskId,
    taskHoverDelay,
    setTaskHoverDelay,
    workflowSimplificationWorkflowEditing,
    redirectToTask,
  } = useWorkflow()
  const { getViewport } = useReactFlow()
  const containerStyles = {
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }
  const iconStyles = {
    color: "white",
  }
  const leftHandleStyles = getVisualBuilderHandleStyles("left")
  const rightHandleStyles = getVisualBuilderHandleStyles("right")

  const isSelected = selectedTaskId === data.taskId
  const isHovered = hoveredTaskId === data.taskId

  useEffect(() => {
    // This whole useEffect can be removed when the
    // workflow_simplification_workflow_editing flag is removed:
    // It used to prevent other cards mouse events when a task was selected
    // From now on, you could hover over other cards regardless if one is selected or not
    if (workflowSimplificationWorkflowEditing) {
      return
    }

    if (!isSelected) {
      const workflowTaskCardContainer = document.querySelector(`[data-id="${data.taskId}"]`)
      if (drawerOpened) {
        workflowTaskCardContainer?.classList.add("!pointer-events-none")
      } else {
        workflowTaskCardContainer?.classList.remove("!pointer-events-none")
      }
    }
  }, [drawerOpened])

  const { isEditing, rightButtonSelected, bottomButtonSelected, leftButtonSelected } = taskState({ data })
  const selectedClasses = workflowSimplificationWorkflowEditing
    ? "selected bg-purple-50 shadow-xl"
    : "selected bg-purple-100"
  const hoverOverClasses = workflowSimplificationWorkflowEditing ? "hover:shadow-xl" : ""
  const borderClasses = isSelected ? "border border-purple-500" : "border border-base"

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHoveredTaskId(null)
    }, 300)
    setTaskHoverDelay(timeout)
  }

  const handleMouseEnter = (taskId: string) => {
    setHoveredTaskId(taskId)
    clearTimeout(taskHoverDelay)
  }

  const handleOnClick = () => {
    if (!workflowSimplificationWorkflowEditing) {
      return
    }

    if (!data.editPath || !data.taskId || isSelected) {
      return
    }

    redirectToTask(data.editPath, JSON.stringify(getViewport()), data.taskId, selectedTaskId)
  }

  const showAddTaskButtons = (path: string) => {
    if (workflowSimplificationWorkflowEditing) {
      return editable && path && (isSelected || isHovered) && !isSubworkflow
    } else {
      return editable && path && isSelected && !isSubworkflow
    }
  }

  const renderEditTaskButton = function (data) {
    if (!data.editPath) {
      return null
    }

    if (workflowSimplificationWorkflowEditing) {
      if (isSelected || isHovered) {
        return (
          <div>
            <ClickToEditTaskButton taskId={data.taskId} isEditing={isSelected} />
          </div>
        )
      }
    } else {
      return (
        isSelected && (
          <div>
            <EditTaskButton editPath={data.editPath} taskId={data.taskId} isEditing={isEditing} />
          </div>
        )
      )
    }
  }

  return data.subworkflowPlaceholder ? (
    <SubworkflowTaskCardNode data={data} />
  ) : (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className={`rounded-md shadow-md bg-white ${borderClasses} p-4 ${
        isSelected ? selectedClasses : ""
      } ${hoverOverClasses} ${isHovered ? "hovered" : ""}`}
      style={containerStyles}
      onMouseEnter={() => handleMouseEnter(data.taskId)}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      {renderEditTaskButton(data)}
      <Handle type="target" position={Position.Left} style={leftHandleStyles} />
      <div className="flex flex-col">
        <div className="space-y-2">
          <div className="flex flex-row items-center">
            <RequestStageIcon stage={data.requestStage} />
            <div className="small-heading truncate" dangerouslySetInnerHTML={{ __html: data.taskNameAndTooltip }} />
            {data.integrationLogo && <img src={data.integrationLogo} className="h-5 w-5 ml-auto"></img>}
          </div>
          <>
            <div className="flex flex-row items-center space-x-2">
              <div dangerouslySetInnerHTML={{ __html: data.assigneeAvatar }} />
              <div className="text-sm font-normal text-gray-600 truncate">
                {data.assigneeName || data.assignmentType}
              </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <div>
                <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full">
                  <i className="fa-solid fa-play fa-2xs" style={iconStyles}></i>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-600 truncate">{taskTriggersText(data.triggers)}</div>
            </div>
          </>
        </div>
        <div className="mt-4 space-y-2">
          <div className={clsx("flex flex-row items-center space-x-2", !data.groupLabelName && "invisible")}>
            <span
              className={`rounded text-gray-800 px-1 py-0.5 text-sm font-medium capitalize ${
                isSelected ? "bg-gray-200" : "bg-gray-100"
              }`}
            >
              {data.groupLabelName || "No Group"}
            </span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.ruleBadge }} />
        </div>
      </div>
      {showAddTaskButtons(data.addTaskPathLeft) && (
        <AddTaskButton
          position="left"
          selected={leftButtonSelected}
          actionPath={data.addTaskPathLeft}
          taskId={data.taskId}
          onHoverOver={() => handleMouseEnter(data.taskId)}
        />
      )}
      {showAddTaskButtons(data.addTaskPathRight) && (
        <AddTaskButton
          position="right"
          selected={rightButtonSelected}
          actionPath={data.addTaskPathRight}
          taskId={data.taskId}
          onHoverOver={() => handleMouseEnter(data.taskId)}
        />
      )}
      {showAddTaskButtons(data.addTaskPathBottom) && (
        <AddTaskButton
          selected={bottomButtonSelected}
          position="bottom"
          actionPath={data.addTaskPathBottom}
          taskId={data.taskId}
          onHoverOver={() => handleMouseEnter(data.taskId)}
        />
      )}
      <Handle type="source" position={Position.Right} style={rightHandleStyles} />
    </div>
  )
}
